import $ from "jquery";
import "jquery-ui";
window.$ = window.jQuery = $;
import intlTelInput from "intl-tel-input";
// import bootstrap from "bootstrap";
import Carousel from 'bootstrap/js/dist/carousel'
const Swal = window.swal = require('sweetalert2');
import SimpleLightbox from "simplelightbox";
import lazyframe from "lazyframe";

var lightbox = new SimpleLightbox('.gallery a', { /* options */ });

// CSRF Token
$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

// Add aria-label
$(function () {
    $("button, a").attr("aria-label", "aria-label");
});

// Meta description
$(function () {
    if (document.querySelector("meta[name='description']") == null) {
        $("head").append('<meta name="description" content="this is new">');
    }
});

// Tooltip
$(function () {
    $('[data-bs-toggle="tooltip"]').tooltip();
});

// Passive event listeners
$(function () {
    if (typeof EventTarget !== "undefined") {
        let func = EventTarget.prototype.addEventListener;
        EventTarget.prototype.addEventListener = function (type, fn, capture) {
            this.func = func;
            if (typeof capture !== "boolean") {
                capture = capture || {};
                capture.passive = false;
            }
            this.func(type, fn, capture);
        };
    }
});


// Youtube
lazyframe(".lazyframe");


// Carousel
$(function () {
    var myCarousel = document.querySelector('#carouselExampleCaptions')
    var carousel = new Carousel(myCarousel, {
        interval: 3000,
        wrap: false
    })
});

// Donation Carousel
$(function () {
    $('#carousel-donates').owlCarousel({
        loop: true,
        margin: 10,
        autoHeight: true,
        nav: true,
        navText: ["<span class='carousel-control-prev-icon' aria-hidden='true'></span>", "<span class='carousel-control-next-icon' aria-hidden='true'></span>"],
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 4
            }
        },
    });
});

/*
$("#carouselDonation .carousel-inner .carousel-item").each(function () {
    var minPerSlide = 4;
    var next = $(this).next();
    if (!next.length) {
        next = $(this).siblings(":first");
    }
    next.children(":first-child").clone().appendTo($(this));
    for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
            next = $(this).siblings(":first");
        }
        next.children(":first-child").clone().appendTo($(this));
    }
});
*/

var url = $('meta[name="translations"]').attr('content');
var translations = (function () {
    var translations = null;
    $.ajax({
        'async': false,
        'global': false,
        'url': url,
        'dataType': 'json',
        'success': function (response) {
            translations = response;
        }
    });
    return translations;
})(); 



// Validate
$(function () {
    $.validator.setDefaults({
        highlight: function (element) {
            $(element).closest(".form-control").addClass("is-invalid");
        },

        unhighlight: function (element) {
            $(element).closest(".form-control").removeClass("is-invalid");
        },

        errorElement: "span",
        errorClass: "invalid-feedback",
        errorPlacement: function (error, element) {
            if (
                element.parent(".input-group").length ||
                element.prop("type") === "checkbox" ||
                element.prop("type") === "radio"
            ) {
                error.insertAfter(element.parent());
            } else {
                error.insertAfter(element);
            }
            return false;
        },
    });

    $("#contact-form").validate({
        lang: 'en',
        rules: {
            name: {
                required: true,
            },
            surname: {
                required: true,
            },
            phone: {
                required: true
            },
            email: {
                required: true,
                email: true,
            },
            subject: {
                required: false,
            },
            message: {
                required: false,
            }
        },
        submitHandler: function (form) {
            const recaptcha_sitekey = $('meta[name="recaptcha_sitekey"]').attr('content');

            /**
             * Hide message elements
             */
            $('#contactModal #responseIcon').addClass('d-none');
            $('#contactModal #responseMessage').addClass('d-none');

            /**
             * Show loading elements
             */
            $('#contactModal #responseLoading').removeClass('d-none');

            /**
             * Show Modal
             */
            $('#contactModal').modal('show');

            /**
             * Refresh Token
             */
            grecaptcha.execute(recaptcha_sitekey, {
                action: 'contact'
            }).then(function (token) {
                var recaptchaResponse = document.getElementById('recaptchaResponse');
                recaptchaResponse.value = token;
            });

            var data = $(form).serialize();

            $.post(form.action, data, function (response) {
                /**
                 * Hide loading elements
                 */
                $('#contactModal #responseLoading').addClass('d-none');

                /**
                 * Show loaded elements after response
                 */
                $('#contactModal #responseIcon').removeClass();
                $('#contactModal #responseIcon').addClass(response.class);
                $('#contactModal #responseMessage').removeClass('d-none');
                $('#contactModal #responseMessage').text(response.message);
                $('#contactModal #buttonClose').removeClass('d-none');

                if (response.type == 'success') {
                    $('#contact-form')[0].reset();
                }
            });
        }
    });

    $('#newsletter-form').validate({
        rules: {
            email: {
                required: true,
                email: true,
            }
        },
        submitHandler: function (form) {
            const recaptcha_sitekey = $('meta[name="recaptcha_sitekey"]').attr('content');

            /**
             * Hide message elements
             */
            $('#newsletterModal #responseIcon').addClass('d-none');
            $('#newsletterModal #responseMessage').addClass('d-none');

            /**
             * Show loading elements
             */
            $('#newsletterModal #responseLoading').removeClass('d-none');

            /**
             * Show Modal
             */
            $('#newsletterModal').modal('show');

            /**
             * Refresh Token
             */
            grecaptcha.execute(recaptcha_sitekey, {
                action: 'contact'
            }).then(function (token) {
                var recaptchaResponse2 = document.getElementById('recaptchaResponse2');
                recaptchaResponse2.value = token;
            });

            $.ajax({
                url: form.action,
                type: form.method,
                data: $(form).serialize(),
                success: function (response) {
                    /**
                     * Show Modal
                     */
                    $('#newsletterModal').modal('show');

                    /**
                     * Hide loading elements
                     */
                    $('#newsletterModal #responseLoading').addClass('d-none');

                    /**
                     * Show loaded elements after response
                     */
                    $('#newsletterModal #responseIcon').removeClass();
                    $('#newsletterModal #responseIcon').addClass(response.class);
                    $('#newsletterModal #responseMessage').removeClass('d-none');
                    $('#newsletterModal #responseMessage').text(response.message);
                    $('#newsletterModal #buttonClose').removeClass('d-none');

                    if (response.type == 'success') {
                        $('#newsletter-form')[0].reset();
                    }
                }
            });
        }
    });

    $("form").each(function () {
        $(".donation-form").validate({
            rules: {
                inputDonation: {
                    required: true,
                    digits: true,
                    number: true,
                },
            },
        });
        $('#payment').validate({
            rules: {
                name: {
                    required: true
                },
                surname: {
                    required: true
                },
                phone: {
                    required: true
                },
                email: {
                    required: true,
                    email: true,
                },
                note: {
                    required: false
                }
            }
        });
    });
    $.validator.addMethod(
        "lettersonly",
        function (value, element) {
            return this.optional(element) || /^[a-z]+$/i.test(value);
        },
        "Lütfen alfabetik değerler giriniz"
    );
});

//Cookie Bar
$(function () {
    if (!localStorage.getItem('acceptCookies')) {
        $('.cookiealert').removeClass('d-none');
    }
    $('.cookiealert').on('click', '.acceptcookies', function () {
        localStorage.setItem('acceptCookies', true);
        $('.cookiealert').addClass('d-none');
    });
});

// Navbar fixed-top
var onResize = function () {
    $("body").css("padding-top", $(".fixed-top").height());
};

// attach the function to the window resize event
$(window).resize(onResize);

// call it also when the page is ready after load or reload
$(function () {
    onResize();
});

$(function () {
    // jQuery plugin to prevent double submission of forms
    jQuery.fn.preventDoubleSubmission = function () {
        $(this).on('submit', function (e) {
            var $form = $(this);

            if ($form.data('submitted') === true) {
                // Previously submitted - don't submit again
                e.preventDefault();
            } else {
                // Mark it so that the next submit can be ignored
                $form.data('submitted', true);
            }
        });

        // Keep chainability
        return this;
    };
});

// Donate Bag
$(function () {
    $(document).on('click', 'input[name="btn-price"]', function () {
        var value = $(this).data('value');
        $(this).parents('form').find('input[name="price"]').val(value);
    });
    $(document).on('input', 'input[name="price"]', function () {
        var value = $(this).val();
        $(this).parents('form').find('input[name="btn-price"]').prop('checked', false);
        $(this).parents('form').find('input[name="btn-price"][data-value="' + value + '"]').data('value', value).prop('checked', true);
    });

    $('.form-donate-constant').each(function () {
        $(this).validate({
            rules: {
                id: {
                    required: true
                }
            },
            submitHandler: function (form) {
                $(this).preventDoubleSubmission();
                var data = $(form).serialize();
                $.ajax({
                    url: form.action,
                    type: form.method,
                    data: $(form).serialize(),
                    processData: false,
                    async: false,
                    success: function (response) {
                        if (response.success) {
                            $.get('/bag', function (response) {
                                $('.render-cart').html(response);
                            });
                            Swal.fire({
                                title: translations.bag.success.title,
                                text: translations.bag.success.description,
                                icon: 'success',
                                confirmButtonText: translations.bag.success.confirm
                            });
                        }
                    }
                });
            }
        });
    });

    $('.form-donate-custom').submit(function (e) {
        e.preventDefault();
        $(this).preventDoubleSubmission();

        var form = $(this);
        var data = $(form).serialize();
        $.ajax({
            url: $(this).data('url'),
            type: 'post',
            data: data,
            processData: false,
            async: false,
            success: function (response) {
                if (response.success) {
                    $.get('/bag', function (response) {
                        $('.render-cart').html(response);
                    });
                    Swal.fire({
                        title: translations.bag.success.title,
                        text: translations.bag.success.description,
                        icon: 'success',
                        confirmButtonText: translations.bag.success.confirm
                    });
                }
            }
        });
        console.log($(this)[0].reset());
    });

    $('.form-zakat-calculate').submit(function (e) {
        e.preventDefault();
        $(this).preventDoubleSubmission();

        var url = $(this).data('url');
        var data = $(this).serialize();
        $.post(url, data, function (response) {
            $('#assets').text(response.assets);
            $('#debts').text(response.debts);
            $('#quorum').text(response.quorum);
            $('#total').text(response.total);
            console.log(response.route);
            if (response.status == 'success') {
                $('#response').html('<div class="alert alert-success">' + response.message + '</div>');
                $('.btn-donate').attr('href', response.route);
            } else {
                $('#response').html('<div class="alert alert-danger">' + response.message + '</div>');
                $('.btn-donate').attr('href', '#.');
            }
        });
    });

    $(document).on('click', '.btn-cart[data-action="destroy"]', function () {
        var uniqid = $(this).data(uniqid);
        $.ajax({
            url: $(this).data('url'),
            type: 'get',
            success: function (response) {
                if (response.success) {
                    $.get('/bag', function (response) {
                        $('.render-cart').html(response);
                    });
                }
            }
        });
    });

    $(document).on('click', '.btn-share', function () {
        var type = $(this).data('type');
        var url = window.location.href;
        switch (type) {
            case 'facebook':
                url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
                window.open(url, 'Facebook', 'height=269,width=550,resizable=1');
                break;
            case 'twitter':
                url = 'https://twitter.com/intent/tweet?url=' + url;
                window.open(url, 'Twitter', 'height=285,width=550,resizable=1');
                break;
            case 'linkedin':
                url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + url;
                window.open(url, 'Twitter', 'height=285,width=550,resizable=1');
                break;
            default:
                break;
        }
    });

    $(document).on('click', '.btn-copy', function () {
        var el = document.createElement('textarea');
        el.value = $(this).data('value');
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    });

    $(document).on('click', '.btn-locale', function () {
        var locale = $(this).data('locale');
        var url = $(this).data('url');
        var href = $('link[rel="alternate"][hreflang="' + locale + '"]').attr('href');
        if (typeof href === 'undefined') {
            window.location.href = url;
        } else {
            window.location.href = href;
        }
    });
});

// Tel input
// // const input = document.querySelector('.intl-tel-input');
// const input = document.querySelector('#phone');
// intlTelInput(input, {
//     initialCountry: "auto",
//     onlyCountries: ["be", "nl", "tr", "fr", "de"],
//     // preferredCountries: ["be", "nl", "tr", "fr", "de"],
//     autoPlaceholder: "aggressive",
//     nationalMode: true,
//     allowDropdown: true,
//     geoIpLookup: function (success) {
//         fetch("https://ipinfo.io/json")
//             .then(function (response) {
//                 if (!response.ok) return success("");
//                 return response.json();
//             })
//             .then(function (ipdata) {
//                 success(ipdata.country_code);
//             })
//             .catch((e) => {
//                 success("be");
//             });
//     },
//     utilsScript: "../public/themes/hasene/src/js/utils.js",
// });



// Donation Inputs
// let inputDonation = document.querySelector("#inputDonation");
// let buttonDonation = document.querySelector("#sendDonation");

// buttonDonation.disabled = true;

// inputDonation.addEventListener("change", stateHandle);

// function stateHandle() {
//   if (document.querySelector("#inputDonation").value === "100") {
//     buttonDonation.disabled = true;
//   } else {
//     buttonDonation.disabled = false;
//   }
// }

